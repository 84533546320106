//import { useEffect } from 'react';
//import { useMsal } from "@azure/msal-react";
//import { loginApiRequest } from "./authConfig";
//import * as microsoftTeams from "@microsoft/teams-js";
//import { TeamsMsal2Provider } from '@microsoft/mgt-teams-msal2-provider';

//export const TeamsAuthPopup = () => {
//    const { instance } = useMsal();
//    const request = {
//        scopes: ["api.scope"]
//    }
//    console.log("test");
//    useEffect(() => {
//        instance.loginRedirect(loginApiRequest);
//    }, []);
//    return (

//        <div>
//            <h4 className="title is-4">
//                Authotization
//            </h4>
//        </div>
//    );
//};

//export default TeamsAuthPopup;

//const styles = {
//    container: {
//        textAlign: 'center',
//    },
//} as const;



import { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from "./authConfig";
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsMsal2Provider } from '@microsoft/mgt-teams-msal2-provider';
import Cookies from 'js-cookie';
export const Login = () => {
    const { instance } = useMsal();




    useEffect(() => {
        instance.loginRedirect(loginApiRequest);
        //instance.handleRedirectPromise()
        //    .then(result => {
        //        setTimeout(function () {
        //            microsoftTeams.authentication.notifySuccess();
        //        }, 10000);
        //    })
        //    .catch((error) => {
        //        microsoftTeams.authentication.notifyFailure(error);
        //    })
        //instance.handleRedirectPromise()
        //.then(result => {
        //    setTimeout(function () {
        //        microsoftTeams.authentication.notifySuccess();
        //    }, 100);
        //})
        //.catch((error) => {
        //    microsoftTeams.authentication.notifyFailure(error);
        //})

      

        //instance.handleRedirectPromise().then((response) => {
        //})
        //    .catch(err => {
        //        console.error(err);
        //    });

        //let resp = instance.acquireTokenSilent(loginApiRequest);

        //let resp = instance.acquireTokenRedirect(loginApiRequest).catch((e) => {
        //    console.log(e);
        //});
        //const accounts = instance.getAllAccounts();
        //const singleAcc = accounts[0];
        //instance.setActiveAccount(singleAcc);
        //instance.acquireTokenSilent(loginApiRequest).then((response) => {

        //    const expires = response.expiresOn;
        //    const token = response.accessToken
        //    const userName = singleAcc.username;
        //    const authJson = JSON.stringify({ userName, token, expires })
        //    microsoftTeams.authentication.notifySuccess(response.accessToken);
        //});
        //console.log("test");

    }, []);
    return (

        <div style={styles.container}>
            <h4 className="title is-4">
                Autoryzacja....
            </h4>
        </div>
    );
};

export default Login;

const styles = {
    container: {
        textAlign: 'center',
    },
} as const;

