import * as React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@fluentui/react-hooks';
import { Icon } from '@fluentui/react/lib/Icon';
import { Container, Row, Col } from "react-bootstrap";
import { LeftNavMenuMobile } from '../LeftNavMenu';
import { useRef, useState } from 'react'
import styles from './LoadFiles.module.css';
import { AppService } from '../../Services/AppService';
import IUserPhoto from '../../Models/UserPhoto';
import IFramePhoto from '../../Models/FramePhoto';

import './LoadFiles.css'; 
import { ToastComponent } from '../Toast';

export const LoadFiles = (props: any) => {
  const [orginalPhoto, setOrginalPhoto] = useState<string | null>(null);
  const [image1Base64, setImage1Base64] = useState<string | null>(null);
  const [image2Base64, setImage2Base64] = useState<string | null>(null);
  const [connectedImageBase64, setConnectedImageBase64] = useState<string | null>(null);
  const [photos, setPhotos] = useState<IFramePhoto[]>([]);
  const [getConvertedPhotos, setConvertedPhotos] = useState<IFramePhoto[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<IFramePhoto | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState<string | null>(null);
  const isMounted = useRef(true);

  const appService = new AppService();

  React.useEffect(() => {
    appService.getUserPhoto().then((photoResult:any) => {
        if (photoResult.data.photo !== null && photoResult.data.photo !== "/Content/images/unknown.png")
      {
            setImage1Base64(photoResult.data.photo);
            const tempModal: any = {
              data1: photoResult.data.photo,
              data2: ""
            };
            appService.sendPhoto(tempModal);
            appService.getOriginalPhoto().then((orginalPhoto:any) => {
              setOrginalPhoto(orginalPhoto.data.base64photo);
          });
          const modal: any = {
            data1: photoResult.data.photo,
            data2: "",
            eventType: "1"
          };
          appService.getReadyToUsePhotos(modal).then((listOfFramePhotos:any) => {
            setPhotos(listOfFramePhotos.data);
      });
      }else{
        setText("Before setting the frames, please set your profile photo...")
      }
    });
  }, []);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, imageNumber: number) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          const base64String = e.target.result as string;
          if (imageNumber === 1) {
            setImage1Base64(base64String.split(",")[1]);
            callApi(base64String.split(",")[1],null);
            setPhotos((prevPhotos) =>
            prevPhotos.map((photo) => ({
              ...photo,
              selected: false,
            }))
          );

          } else if (imageNumber === 2) {
            setImage2Base64(base64String);
          }
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const toggleSelection = (id: number) => {
    setPhotos((prevPhotos) =>
      prevPhotos.map((photo) =>
        photo.id === id ? { ...photo, selected: !photo.selected } : { ...photo, selected: false }
      )
    );
  };
  
  const handlePhotoClick = (photo: IFramePhoto) => {
    setSelectedPhoto(photo);
  };

  const handleCloseClick = () => {
    setSelectedPhoto(null);
  };

  const handleSelectClick = () => {
    if (selectedPhoto) {
      toggleSelection(selectedPhoto.id);
      setSelectedPhoto(null);
      callApi(null,selectedPhoto.base64photo);
    }
  };

  const handleDeselectClick = () => {
    if (selectedPhoto) {
      toggleSelection(selectedPhoto.id);
      setSelectedPhoto(null);
      callApi(image1Base64,null);
    }
    setPhotos((prevPhotos) =>
    prevPhotos.map((photo) => ({
      ...photo,
      selected: false,
    })));
  };
  const handleLoadOriginalPhoto = () => {
    setImage1Base64(orginalPhoto);
    callApi(orginalPhoto,null);
  };

  const renderPhotoList = () => {
    return (
      <div className="photo-list">
        {photos.map((photo) => (
          <div
            key={photo.id}
            className={`photo-item ${photo.selected ? 'selected' : ''}`}
            onClick={() => {
              handlePhotoClick(photo);
            }}
          >
            {photo.name}
          </div>
        ))}
      </div>
    );
  };

  const renderExpandedPhoto = () => {
    if (selectedPhoto) {
      return (
        <div 
          className={`expanded-photo photo-item ${selectedPhoto.selected ? 'selected' : ''}`}>
          <img src={`data:image/jpeg;base64,${selectedPhoto.base64photo}`} alt={selectedPhoto.name} />
          <div className="button-container">
            <button onClick={handleCloseClick}>Close</button>
            {!selectedPhoto.selected && (
                 <button onClick={handleSelectClick}>
                 Select
               </button>
              )}
            {selectedPhoto.selected && (
                 <button onClick={handleDeselectClick}>
                 Deselect
               </button>
              )}
          </div>
        </div>
      );
    }
    return null;
  };

  const callApi = async (image1str: string | null, image2Base64str: string | null) => {

    if (image1Base64 || image1str ) {
      try {
        const modal: any = {
          data2: image2Base64str,
        };
        if (image1str) {
          modal.data1 = image1str;
        } else {
          modal.data1 = image1Base64;
        }
        appService.sendPhoto(modal).then((result:any) => {
          const connectedImageBase64 = result.data;
          setConnectedImageBase64(connectedImageBase64);
      });
      } catch (error) {
        console.error('API Error:', error);
      }
    }
  };
  const callApiSaveImage = async (imageToSaveBase64: string | null)=> {
    if (imageToSaveBase64 ) {
      try {
        const modal: any = {
          data1: imageToSaveBase64
        }
        setIsLoading(true);
        appService.updatePhoto(modal).then((result:any) => {
          setTimeout(() => {
            appService.getUserPhoto().then((photoResult:any) => {
              setImage1Base64(photoResult.data.photo);
            });
            setToastMessage('Photo changed successfully. different systems will take different days to synchronize');
            setShowToast(true);
            setIsLoading(false);
          }, 3000);
      });
      } catch (error) {
        setIsLoading(false);
        console.error('API Error:', error);
      }
    }
  };
    return (

        <>
        
        <div>
        <Row>
            <Col lg={3}>
            {photos.length > 0 && image1Base64 ? (
              <span>
                          <p>Actual Image:</p>
                <div>       
                  <img src={`data:image/png;base64,${image1Base64}`} alt="Connected"  width="200px" height="200px"/>
                </div>
                </span>
              ):(<span></span>)}
            </Col>
            {photos.length === 0 && !text ? (
        <Col lg={12}><Col lg={6}></Col><Col lg={2} className="centerSpinner"><div><div className="loading-info spinner-border text-primary" role="status"></div> </div></Col></Col>
            ):(        photos.map((singleFrame) => (
              <Col lg={2}>
             <img src={`data:image/png;base64,${singleFrame.base64photo}`} alt="Connected" width="200px" height="200px"/>
             <PrimaryButton onClick={() => callApiSaveImage(singleFrame.base64photo)} text="Save image" />
             </Col>
         )))}

            {text && <span className="centerInfoText">{text}</span>}
    

              <div>
                    <div>
                    {isLoading && (
                      <div className="toast show position-fixed top-50 start-50 translate-middle">
                        <div className="d-flex justify-content-center my-3">
                          <div>Saving photo please wait...</div>
                          <div className="spinner-border text-primary" role="status"></div>
                        </div>
                      </div>
      )}
        <ToastComponent message={toastMessage} show={showToast} onClose={() => setShowToast(false)} isLoading={isLoading} />
                  </div>
                  </div>
              

        </Row>
      {/* <input
        type="file"
        accept="image/*"
        onChange={(e) => handleImageUpload(e, 1)}
      /> */}


    </div>
      </>
    );
  };