import * as React from 'react';
import { SearchBox, ISearchBoxStyles } from '@fluentui/react/lib/SearchBox';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { ITheme, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
const theme: ITheme = getTheme();
const { palette, fonts } = theme;
const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };

const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
        marginLeft:'15px',
        backgroundColor: '#f0ecec',
        border: '0px solid black',
        width: '100%',
/*        minWidth: '310px',*/
        borderRadius: '25px',
        selectors: {
            ':hover': {
                borderRadius: '25px',
            },
            ':after': {
                borderRadius: '25px',
            },
        },
    }
};

/* eslint-disable react/jsx-no-bind */
export const SearchBoxServices = (props: any) => {

    return (
        <Stack tokens={stackTokens}>
            <SearchBox
                styles={searchBoxStyles}
                placeholder="Wyszukaj"
                onChange={newValue => props.onSearchData(newValue?.target.value)} 
                onSearch={newValue => props.onSearchData(newValue)} />
        </Stack>
    );
};



