import React, { useEffect, useState } from 'react';
import banner from '../../Assets/banner.png';
import { Col, Row } from "react-bootstrap"
import { useMsal } from "@azure/msal-react";
import { ITheme, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { ButtonSendFeedback } from '../ButtonSendFeedback';

const theme: ITheme = getTheme();
const { palette, fonts } = theme;
interface TopBannerProps {
    description: string; 
  }
export const TopBanner: React.FC<TopBannerProps> = ({ description }) => {

    const { instance } = useMsal();

    const accounts = instance.getAllAccounts();
    let activeAccount = null;
    let roles = null;
    if (accounts && accounts.length === 1) {
        activeAccount = accounts[0].name?.split(" ")[1];
        roles = accounts[0].idTokenClaims?.roles?.toString(); 
    } else if (accounts && accounts.length > 1) {
        console.log('ERROR: Multiple users logged in');
    }
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    function logout() {
        window.localStorage.clear();
        window.location.reload();
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 1000;

    return (
        <div>
            <i onClick={logout} title="Wyloguj" className={'bi bi-box-arrow-right'} style={{ position: 'absolute', top: '10px', right: '20px', fontSize: 25, color: 'black', cursor: 'pointer' }}></i>
            <Row>
                <Col xs={7} lg={4} xxl={3} style={{ padding: '0' }}>
                    <img src={banner} alt="Banner" />
                </Col>

                <Col xs={5} lg={6} xxl={7} style={{ textAlign: 'left' }} className="align-self-center">

                    <h1 style={{ fontSize: isMobile ? '15px' : '25px', fontWeight: 'bold', color: 'black' }} >Cześć {activeAccount}!</h1>
                    <span style={{ color: 'black', fontSize: isMobile ? '11px' : '18px' }}>{description}</span>

                </Col>
             
                <Col lg={2} className="align-self-center">
                    <Row className="align-self-top">
                    </Row>
                    <Row>
                        <Col lg={10}>
                            <ButtonSendFeedback />
                            </Col>
                        </Row>
                </Col>

            </Row >
        </div>
    );
};
