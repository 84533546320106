import React, { useEffect, useState } from 'react';
import { FocusZone } from '@fluentui/react/lib/FocusZone';
import { List } from '@fluentui/react/lib/List';
import { IRectangle } from '@fluentui/react/lib/Utilities';
import { createListItems, IExampleItem } from '@fluentui/example-data';
import { ServiceDetailedInfo } from '../ServiceDetailedInfo';
import { useConst } from '@fluentui/react-hooks';
import itLogo from '../../Assets/it.png';
import axios from "axios";
import { ITheme, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import IService from '../../Models/Service';
import './GridItems.css';
const theme: ITheme = getTheme();
const { palette, fonts } = theme;
const ROWS_PER_PAGE = 1;
const MAX_ROW_HEIGHT = 290;
const classNames = mergeStyleSets({
    listGridExample: {
        overflow: 'hidden',
        fontSize: 0,
        position: 'relative',
        marginLeft: '0%',
        marginRight: '5%',
    },
    listGridExampleTile: {
        paddingLeft: '20px',
        marginBottom: '20px',
        marginRight: '10px',
        marginLeft: '10px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)',
        textAlign: 'center',
        outline: 'none',
        position: 'relative',
        float: 'left',
        background: '#f5f5f5',
        selectors: {
            'focus:after': {
                content: '',
                position: 'absolute',
                left: 2,
                right: 2,
                top: 2,
                bottom: 2,
                boxSizing: 'border-box',
                border: `1px solid ${palette.white}`,
            },
        },
    },
    listGridExampleSizer: {
        paddingBottom: '270px',
        marginBottom: '20pxs'
    },
    listGridExamplePadder: {
        position: 'absolute',
        left: 2,
        top: 2,
        right: 2,
        bottom: 2,
    },
    listGridExampleLabel: {
        background: palette.themeLight,
        color: 'black',
        height: '40px',
        position: 'absolute',
        padding: 10,
        left: 0,
        width: '100%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'left',
    },
    listGridExampleLabel2: {
        color: 'black',
        position: 'absolute',
        padding: 10,
        right: 0,
        width: '100%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'right',
    },
    listGridExampleName: {
        position: 'absolute',
        top: 70,
        left: 5,
        height: '30px',
        fontSize: '15px',
        padding: '5px',
        boxSizing: 'border-box',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    listGridExampleDescription: {
        position: 'absolute',
        top: 115,
        left: 5,
       
        width: '96%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'justify',
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    listGridExampleSystemIcon: {
        background: 'white',
        position: 'absolute',
        top: 10,
        left: 10,
        width: '50px',
        height: '50px',
        fontSize: '10px',
        boxSizing: 'border-box',
        border: `1px solid ${palette.neutralTertiary}`,
        zIndex: 1,
    },
    listGridExampleSystemIconText: {
        position: 'absolute',
        top: 10,
        left: 15,
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
    },
    listGridExampleInfoBlock: {
        //background: '#f5f5f5',
        //position: 'absolute',
        //top: 210,
        //right: 5,
        //width: '50%',
        //fontSize: fonts.large.fontSize,
        //boxSizing: 'border-box'


        //background: '#f5f5f5',
        //color: 'black',
        //position: 'absolute',
        //padding: 10,
        //left: 0,
        //bottom: 0,
        //height:40,
        //width: '100%',
        //fontSize: fonts.small.fontSize,
        //boxSizing: 'border-box',
        //textAlign: 'left',
    },
    listGridExampleFavorite: {
        position: 'absolute',
        top: 230,
        left: 10,
        fontSize: fonts.large.fontSize,
        boxSizing: 'border-box'
    },

    listGridExampleInfo: {
        position: 'absolute',
        top: 230,
        right: 10,
        fontSize: fonts.large.fontSize,
        boxSizing: 'border-box'
    },
    listGridExampleStatusOperational: {
        background: '#e6ffe6',
        color: 'black',
        position: 'absolute',
        top: 240,
        borderRadius: '25px',
        right: '25%',
        width: '50%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    listGridExampleStatusPlanned: {
        background: ' #ffffcc',
        color: 'black',
        position: 'absolute',
        top: 240,
        borderRadius: '25px',
        right: '25%',
        width: '50%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    listGridExampleStatusRetracted: {
        background: '#ffd6cc',
        color: 'black',
        position: 'absolute',
        top: 240,
        borderRadius: '25px',
        right: '25%',
        width: '50%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    listGridExampleAvaiable: {
        background: palette.themeLight,
        color: 'black',
        position: 'absolute',
        top: 35,
        borderBottomLeftRadius: '25px',
        right: '0',
        width: '60%',
        height: '30px',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    listGridExampleGMP: {
        background: "#EDEDED",
        color: 'black',
        position: 'absolute',
        top: 36,
        right: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 5,
        width: '25%',
        borderBottomLeftRadius: '35px',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'right',
    },
});

type Service = {
    description: string;
    name: number;
    status: string;
}

export const GridItemsOnLoad: React.FunctionComponent<{
    data: IService[] | undefined
}> = ({ children, data }) => {

    const [width, setWidth] = useState<number>(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    function truncate(source: any, size: any) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 525;

    const columnCount = React.useRef(0);
    const rowHeight = React.useRef(0);

    const getItemCountForPage = React.useCallback((itemIndex: number | undefined, surfaceRect: IRectangle | undefined) => {
        if (itemIndex === 0 && surfaceRect != null) {
            columnCount.current = Math.round(surfaceRect.width / MAX_ROW_HEIGHT);
            rowHeight.current = Math.floor(surfaceRect.width / columnCount.current);
        }
        return columnCount.current * ROWS_PER_PAGE;
    }, []);

    const onRenderCell = React.useCallback((item: IExampleItem | undefined, index: number | undefined) => {

        return (
            <div
                className={classNames.listGridExampleTile}
                data-is-focusable
                style={{
                    width: (100 / columnCount.current) - 5 + '%',
                }}
            >
                <div className={classNames.listGridExampleSizer}>
                    <div className={classNames.listGridExamplePadder}>
                        {/*<div className={classNames.listGridExampleSystemIcon}>*/}
                        {/*</div>*/}

                        <span className={classNames.listGridExampleLabel}></span>
                        <span className={classNames.listGridExampleLabel2}></span>
                        <div className={classNames.listGridExampleName} title=''></div>
                        <span className={classNames.listGridExampleDescription} title=''></span>
     {/*                   <div className={classNames.listGridExampleInfoBlock}></div>*/}
                        <div className={classNames.listGridExampleFavorite}>

                        </div>
                    </div>
                </div>
            </div>
        );

    }, []);

    const getPageHeight = React.useCallback((): number => {
        return rowHeight.current * ROWS_PER_PAGE;
    }, []);

    const items = useConst(() => createListItems(500));
    return (
        <FocusZone>
            <List
                className={classNames.listGridExample}
                items={items}
                getItemCountForPage={getItemCountForPage}
                getPageHeight={getPageHeight}
                renderedWindowsAhead={20}
                onRenderCell={onRenderCell}
            />
        </FocusZone>
    );
    };
