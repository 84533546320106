import React, { useEffect, useState } from 'react';
import banner from '../../Assets/banner.png';
import { Col, Row } from "react-bootstrap"
import { SearchBoxServices } from '../SearchBoxServices';
import { TopNavMenu } from '../TopNavMenu';
import { useMsal } from "@azure/msal-react";
import { ITheme, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { ButtonSendFeedback } from '../ButtonSendFeedback';

const theme: ITheme = getTheme();
const { palette, fonts } = theme;
interface TopBannerMobileProps {
    description: string; 
  }
export const TopBannerMobile: React.FC<TopBannerMobileProps> = ({ description }) => {

    const { instance } = useMsal();

    const accounts = instance.getAllAccounts();
    let activeAccount = null
    if (accounts && accounts.length === 1) {
        activeAccount = accounts[0].name?.split(" ")[1];
    } else if (accounts && accounts.length > 1) {
        console.log('ERROR: Multiple users logged in');
    }
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    function logout() {
        window.localStorage.clear();
        window.location.reload();
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 1000;

    return (
        <div>
            <Row>
                <Col xs={7} lg={4} style={{ padding: '0' }}>
                    <img src={banner} alt="Banner" />
                </Col>

                <Col xs={5} lg={8} style={{ textAlign: 'left' }} className="align-self-center">

                    <h1 style={{ fontSize: isMobile ? '15px' : '25px', fontWeight: 'bold', color: 'black' }} >Cześć {activeAccount}!</h1>
                    <span style={{ color: 'black', fontSize: isMobile ? '11px' : '18px' }}>{description}</span>
                </Col>
            </Row >
        </div>
    );
};
