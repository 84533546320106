import { useMsal } from "@azure/msal-react";
import banner from '../img/banner.png';
import { IPivotStyles, IStyleSet, Label, Pivot, PivotItem } from '@fluentui/react';
import { Col, Row } from "react-bootstrap";
import React, { useState } from 'react';
import { ITheme, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
const theme: ITheme = getTheme();
const { palette, fonts } = theme;
interface IProps {
}
interface IState {
    selectedKey?: string;
}

const pivotStyles1200: Partial<IStyleSet<IPivotStyles>> = {
    link: {
        margin: '0',
        fontSize: fonts.medium.fontSize,
        textAlign: 'left',
    }
};

const pivotStyles: Partial<IStyleSet<IPivotStyles>> = {
    link: {
        margin: '0',
        fontSize: fonts.large.fontSize,
        textAlign: 'left',
        /*width: "100px"*/
    }
};
export const TopNavMenuSelectedDepartment = (props: any) => {

    const itemName = (item:any) => {
        if (item === 'all') {
            return 'Wszystkie';
        } else if (item === 'favourite') {
            return 'Ulubione';
        } else if (item === 'last') {
            return 'Ostatnio uruchamiane aplikacje';
        } else if (item === 'popular') {
            return 'Najpopularniejsze w organizacji';
        } else {
            return item;
        }
    }


    return (
        <Pivot styles={props.width1200OrLower ? pivotStyles1200 : pivotStyles} selectedKey={props.selectedKey}>
            {props.viewIsMobile || (props.selectedKey !== 'all' && props.selectedKey !== 'favourite' && props.selectedKey !== 'last' && props.selectedKey !== 'popular') ?
                <PivotItem headerText={itemName(props.selectedKeyName)} itemKey={props.selectedKey}></PivotItem> : ''}
        </Pivot>
    );
}
