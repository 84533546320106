import * as React from 'react';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { Checkbox, ICheckboxProps, ICheckboxStyles } from '@fluentui/react/lib/Checkbox';
import { ITheme, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
const theme: ITheme = getTheme();
const { palette, fonts } = theme;
const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };


const checkboxStyles: Partial<ICheckboxStyles> = {
    root: {
        paddingTop:'5px',
    }
};

export const CheckBoxGmp = (props: any) => {
    return (
    
            <Checkbox label="GMP" checked={props.valueCheckbox} onChange={props.onSelectCheckbox} styles={checkboxStyles}/>
    
    );
};



