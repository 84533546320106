import React, { useEffect, useState } from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { useBoolean } from '@fluentui/react-hooks';
import styles from './ButtonSendFeedback.module.css';
import IRatingFeedback from '../../Models/RatingFeedback';
import { AppService } from '../../Services/AppService';
const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
};
const dialogContentProps = {
    type: DialogType.largeHeader,
    title: 'Polpharma IT',
    subText: 'Podziel się z nami swoją opinią',
};

export const ButtonSendFeedback: React.FunctionComponent = () => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [evaluationText, setEvaluationText] = useState('');
    const appService = new AppService();
    function sendRating() {
        const modal: IRatingFeedback = {
            evaluation: evaluationText,
            ratingValue: rating
        };
        appService.sendRating(modal).then((result) => {
            toggleHideDialog();
            setEvaluationText('');
            setRating(0);
        });
    }

    return (
        <>
            <PrimaryButton onClick={toggleHideDialog} text="Prześlij opinie" />
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modelProps}
                containerClassName={'ms-dialogMainOverride ' + styles.textDialog}
            >
                <div className="star-rating">
                    {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                            <i
                                
                                key={index}
                                style={{
                                    fontSize: 36, color: '#FFC000', cursor: 'pointer' }}
                                className={index <= (hover || rating) ? 'bi bi-star-fill' : 'bi bi-star'}
                                onClick={() => setRating(index)}
                                onMouseEnter={() => setHover(index)}
                                onMouseLeave={() => setHover(rating)}
                            >
                            </i>
                        );
                    })}
                </div>
                <div style={{ marginTop: '30px' }}>
                    <TextField value={evaluationText} multiline rows={3}
                        onChange={(event, value) => { setEvaluationText(value ?? ''); }}
                    />
                    </div>
                <DialogFooter>
                    <PrimaryButton onClick={sendRating} text="Wyślij" />
                    <DefaultButton onClick={toggleHideDialog} text="Anuluj" />
                </DialogFooter>
            </Dialog>
        </>
    );
};
