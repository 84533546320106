import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useState } from "react";
import IClickStatistic from "../Models/ClickStatistic";
import ISearchStatistic from "../Models/SearchStatistic";
import IClickStatisticGroup from "../Models/ClickStatisticGroup";
import IFavourite from "../Models/Favourite";
import IService from "../Models/Service";
import IAddRemoveFavourite from "../Models/AddRemoveFavourite";
import IResponseResult from "../Models/ResponseResult";
import IRatingFeedback from "../Models/RatingFeedback";
import IUserPhoto from "../Models/UserPhoto";
import IFramePhoto from "../Models/FramePhoto";
import IServerInfo from "../Models/ServerInfo";

export class AppService {

    public async getServices() {
        return axios.get<Array<IService>>("home/get-service-map-items");
    }

    public async getFavourite() {
        return axios.get<Array<IFavourite>>("favourites/get-all-favourites");
    }

    public async addFavourite(model: IAddRemoveFavourite) {
        return axios.post<IResponseResult>("favourites/add-favourites", model);
    }

    public async removeFavourite(model: IAddRemoveFavourite) {
        return axios.post<IResponseResult>("favourites/remove-favourites", model);
    }

    public async getLastRun() {
        return axios.get<Array<IClickStatistic>>("statistic/get-my-last-run");
    }

    public async getPopular() {
        return axios.get<Array<IClickStatisticGroup>>("statistic/get-most-popular-services");
    }

    public async addClickStatistic(model: IClickStatistic) {
        return axios.post<IResponseResult>("statistic/add",model);
    }

    public async addSearchStatistic(model: ISearchStatistic) {
        return axios.post<IResponseResult>("statistic/add-search",model);
    }

    public async checkAuthorized() {
        return axios.get<Array<IClickStatisticGroup>>("home/check-auth");
    }

    public async sendRating(model: IRatingFeedback) {
        return axios.post<IResponseResult>("home/add-rating",model);
    }

    public async addUser(user: any) {
        const response = await axios.post(`/api/user`, { user });
        return response.data;
    }

    public async sendPhoto(model: any) {
        return axios.post<IResponseResult>("photo/create-photo", model);
    }

    public async getUserPhoto() {
        return axios.get<IUserPhoto>("home/get-current-user");
    }

    public async getListOfFramePhotos(eventType:string) {
        return axios.get<Array<IFramePhoto>>("photo/list-frame-photo?eventType="+eventType);
    }

    public async updatePhoto(model: any) {
        return axios.post<IResponseResult>("photo/update-photo", model);
    }

    public async getOriginalPhoto() {
        return axios.get<IResponseResult>("photo/original-photo");
    }
    
    public async getReadyToUsePhotos(model: any) {
        return axios.post<IResponseResult>("photo/list-frame-photo-ready-to-use", model);
    }

    public async getServerInfo(itemCode:string) {
        return axios.get<Array<IServerInfo>>("home/get-server-info?itemCode="+itemCode);
    }

   
}