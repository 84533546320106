import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { PivotItem } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Nav, INavLinkGroup, INavStyles, INavLink } from '@fluentui/react/lib/Nav';
import { ITheme, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { TopBanner, TopBannerMobile } from '../Components/TopBanner';
import { TopNavMenu, TopNavMenuSelectedDepartment } from '../Components/TopNavMenu';
import { LeftNavMenu, LeftNavMenuMobile } from '../Components/LeftNavMenu';
import { SearchBoxServices } from '../Components/SearchBoxServices';
import { CheckBoxGmp } from '../Components/CheckBoxGmp';
import { GridItems, GridItemsOnLoad } from '../Components/GridItems';
import { ButtonOpenLeftMenu } from '../Components/ButtonOpenLeftMenu';
import { AppService } from '../Services/AppService';
import { ButtonSendFeedback } from '../Components/ButtonSendFeedback';
import { AppConfigurationClient } from "@azure/app-configuration";
import IService from '../Models/Service';
import ISearchFilter from '../Models/SearchFilter';
import IFavourite from '../Models/Favourite';
import IClickStatistic from '../Models/ClickStatistic';
import IClickStatisticGroup from '../Models/ClickStatisticGroup';
import IAddRemoveFavourite from '../Models/AddRemoveFavourite';
import ISearchStatistic from "../Models/SearchStatistic";
import './home.css';
import { useMsal } from "@azure/msal-react";
import { LoadFiles } from '../Components/LoadFiles';
const theme: ITheme = getTheme();
const { palette, fonts } = theme;

export default function ChangePhoto() {

    const [width, setWidth] = useState<number>(window.innerWidth);
    const [dataFromDB, setDataFromDB] = useState<IService[]>();
    const [dataFilter, setDataFilter] = useState<IService[]>();
    const [favouritesDB, setFavouritesDB] = useState<IFavourite[]>();
    const [myLastRunDB, setMyLastRunDB] = useState<IClickStatistic[]>();
    const [popularDB, setPopularDB] = useState<IClickStatisticGroup[]>();
    const [gmpFilter, setGmpFilter] = useState<boolean>(false);
    const [authOn, setAuthOn] = useState<boolean>(false);
    const [addingSearchStatistic, setAddingSearchStatistic] = useState<boolean>(false);
    const [hideSearchbox, setHideSearchbox] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [visibleScrollToTop, setVisibleScrollToTop] = useState(false)
    const menuSelection = useRef('all');
    const menuSelectionName = useRef('Wszystkie');
    const searchTextRef = useRef('');
    const [searchBoxValue, setSearchBoxValue] = useState<string>('');
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const appService = new AppService();
    const { instance } = useMsal();

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisibleScrollToTop(true)
        }
        else if (scrolled <= 300) {
            setVisibleScrollToTop(false)
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    window.addEventListener('scroll', toggleVisible);

    const searchDataHandler = (arg: any) => {
        let searchText = '';
        if (arg !== undefined) {
            searchText = arg.toLowerCase();
        }
        searchTextRef.current = searchText;
        setSearchBoxValue(searchText);
        filterDataInGrid(searchText, gmpFilter, menuSelection.current);
        if (searchText.length >= 3) {
            if (!addingSearchStatistic) {
                setAddingSearchStatistic(true);
                setTimeout(function () {
                    const modal: ISearchStatistic = {
                        context: 'KU',
                        searchText: searchTextRef.current,
                        userName: ''
                    };
                    appService.addSearchStatistic(modal).then((result) => {
                        setAddingSearchStatistic(false);
                    });
                }, 6000);
            }
        }
    }


    const filterDataInGrid = (searchBox: string, gmp: boolean, leftMenu: string) => {

        console.log('searchBox: ' + searchBox);
        console.log('gmp'); console.log(gmp);
        console.log('leftMenu: ' + leftMenu);
        let menuItem = '';
        if (leftMenu.toLowerCase() !== 'all' && leftMenu.toLowerCase() !== 'popular' && leftMenu.toLowerCase() !== 'last' && leftMenu.toLowerCase() !== 'favourite') {
            menuItem = leftMenu.toLowerCase();
        } else {
            menuSelection.current = 'all';
        }

        if (menuItem !== '') {
            if (gmp) {
                setDataFilter(dataFromDB?.filter(service =>
                    service.gmp == true &&
                    service.bpArea?.toLowerCase() === menuItem &&
                    (
                        service.name?.toLowerCase().includes(searchBox) ||
                        service.description?.toLowerCase().includes(searchBox) ||
                        service.code?.toLowerCase().includes(searchBox)))
                );
            } else {
                setDataFilter(dataFromDB?.filter(service =>
                    service.bpArea?.toLowerCase() === menuItem &&
                    (
                        service.name?.toLowerCase().includes(searchBox) ||
                        service.description?.toLowerCase().includes(searchBox) ||
                        service.code?.toLowerCase().includes(searchBox)))
                );
            }
        } else {
            if (gmp) {
                setDataFilter(dataFromDB?.filter(service =>
                    service.gmp == true &&
                    service.bpArea?.toLowerCase().includes(menuItem) &&
                    (
                        service.name?.toLowerCase().includes(searchBox) ||
                        service.description?.toLowerCase().includes(searchBox) ||
                        service.code?.toLowerCase().includes(searchBox)))
                );
            } else {
                setDataFilter(dataFromDB?.filter(service =>
                    service.bpArea?.toLowerCase().includes(menuItem) &&
                    (
                        service.name?.toLowerCase().includes(searchBox) ||
                        service.description?.toLowerCase().includes(searchBox) ||
                        service.code?.toLowerCase().includes(searchBox)))
                );
            }
        }
    }

    const filterDataInGridByTopMenu = (keyName: string | undefined) => {
        if (keyName !== undefined && favouritesDB !== undefined) {
            menuSelection.current = keyName;
            if (keyName === 'all') {
                setDataFilter(dataFromDB);
            } else if (keyName === 'favourite') {
                let yFilter = favouritesDB?.map(itemY => { return itemY.itemCode; });
                let myArrayFiltered = yFilter?.filter(el => {
                    return dataFromDB?.some(f => {
                        return f.code === el;
                    });
                });
                setDataFilter(dataFromDB?.filter(item => myArrayFiltered?.includes(item.code)));
            } else if (keyName === 'last') {
                //appService.getLastRun().then((last) => {
                //    let tempData = new Array();
                //    setMyLastRunDB(last.data);
                //    let yFilter = last.data?.map(itemY => { return itemY.itemCode; });
                //    yFilter.forEach(x => tempData.push(
                //        dataFromDB?.find(z => z?.code === x)
                //    ))
                //    setDataFilter(tempData);
                //});
                let tempData = new Array();
                let yFilter = myLastRunDB?.map(itemY => { return itemY.itemCode; });
                let myArrayFiltered = yFilter?.filter(el => {
                    return dataFromDB?.some(f => {
                        return f.code === el;
                    });
                });
                myArrayFiltered?.forEach(x => tempData.push(
                    dataFromDB?.find(z => z?.code === x)
                ))
                setDataFilter(tempData);
            } else if (keyName === 'popular') {
                let yFilter = popularDB?.map(itemY => { return itemY.itemCode; });
                let myArrayFiltered = yFilter?.filter(el => {
                    return dataFromDB?.some(f => {
                        return f.code === el;
                    });
                });
                setDataFilter(dataFromDB?.filter(item => myArrayFiltered?.includes(item.code)));
            } else if (keyName === '') {
                let yFilter = favouritesDB?.map(itemY => { return itemY.itemCode; });
                setDataFilter(dataFromDB?.filter(item => yFilter?.includes(item.code)));
            } else {
                filterDataInGrid(searchBoxValue, gmpFilter, keyName);
            }
        }
    }

    const topMenuItemClickHandler = (item?: PivotItem) => {
        filterDataInGridByTopMenu(item?.props.itemKey);
        if (item?.props.itemKey !== 'all') {
            setHideSearchbox(true);
        } else {
            setHideSearchbox(false);
        }
    }
    const onTitleClickHandler = (service: IService | undefined) => {
        if (service !== undefined && service.url !== '') {
            const modal: IClickStatistic = {
                context: 'KU',
                itemCode: service?.code,
                itemName: service?.name,
                userName: ''
            };
            if (myLastRunDB !== undefined) {
                setMyLastRunDB((prevState) => prevState !== undefined ? ([modal].concat(prevState.filter(x => x.itemCode !== modal.itemCode)).slice(0, 16)) : undefined);
            }
            appService.addClickStatistic(modal).then((result) => {
                if (menuSelection.current === 'last') {
                    appService.getLastRun().then((last) => {
                        let tempData = new Array();
                        let yFilter = last.data.map(itemY => { return itemY.itemCode; });
                        yFilter?.forEach(x => tempData.push(
                            dataFromDB?.find(z => z?.code === x)
                        ))
                        setDataFilter(tempData);
                    });
                }
            });
        }
    }


    const addFavouriteClickHandler = (service: IService | undefined) => {

        if (service !== undefined) {
            const modal: IAddRemoveFavourite = {
                context: 'KU',
                itemCode: service?.code,
            };

            const newFav: IFavourite = {
                context: 'KU',
                itemCode: service?.code
            };

            let objIndexDB = dataFromDB?.findIndex((obj => obj.code == service.code));
            let objIndexFilter = dataFilter?.findIndex((obj => obj.code == service.code));

            if (dataFromDB !== undefined && objIndexDB !== undefined) {
                dataFromDB[objIndexDB].isFavourite = true;
            }

            //if (dataFilter !== undefined && objIndexFilter !== undefined) {
            //    dataFilter[objIndexFilter].isFavourite = true;
            //}

            setFavouritesDB((prevState) => ([...prevState ?? [], newFav]));
            appService.addFavourite(modal).then((addResult) => {

            });
        }
    }

    const removeFavouriteClickHandler = (service: IService | undefined) => {
        if (service !== undefined) {
            const modal: IAddRemoveFavourite = {
                context: 'KU',
                itemCode: service?.code,
            };


            let objIndexDB = dataFromDB?.findIndex((obj => obj.code == service.code));
            let objIndexFilter = dataFilter?.findIndex((obj => obj.code == service.code));

            if (dataFromDB !== undefined && objIndexDB !== undefined) {
                dataFromDB[objIndexDB].isFavourite = false;
            }


            setFavouritesDB((prevState) => prevState?.filter((item) => item.itemCode !== service.code));
            if (menuSelection.current === 'favourite') {
                setDataFilter((prevState) => prevState?.filter(x => x.code !== service.code));
            }
            appService.removeFavourite(modal).then((result) => {

            });
        }
    }

    const handleOnFavouriteClickChangeIcon: React.MouseEventHandler<HTMLDivElement> = (e) => {

        if (menuSelection.current !== 'favourite') {
            if (e.currentTarget.classList.contains('bi-star-fill')) {
                e.currentTarget.classList.remove('bi-star-fill', 'bi-star')
                e.currentTarget.classList.add('bi-star')
                e.currentTarget.title = 'Dodaj do ulubionych';
            } else {
                e.currentTarget.classList.remove('bi-star-fill', 'bi-star')
                e.currentTarget.classList.add('bi-star-fill')
                e.currentTarget.title = 'Usu� z ulubionych';
            }
        }
    };

    const leftMenuItemClickHandler = React.useCallback(
        (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
            if (item?.key === 'favourite' || item?.key === 'last' || item?.key === 'popular') {
                setHideSearchbox(true);
            } else {
                setHideSearchbox(false);
            }
            if (item?.name !== undefined) {
                menuSelectionName.current = item?.name;
            }
            console.log('link:' + item?.key)
            if (item?.key !== undefined) {
                if (item?.key === 'logout') {
                    window.localStorage.clear();
                    window.location.reload();
                } else {
                    if (width >= 1000) {
                        menuSelection.current = item?.key;
                        filterDataInGrid(searchBoxValue, gmpFilter, item?.key);
                    }
                    else {
                        filterDataInGridByTopMenu(item?.key);
                    }
                }
            }
            dismissPanel();
        },
        [width, setDataFilter, dataFromDB, favouritesDB, gmpFilter, searchBoxValue],
    );

    const gmpFilterHandler = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setGmpFilter(!!checked);
            filterDataInGrid(searchBoxValue, !!checked, menuSelection.current);
        },
        [setDataFilter, dataFromDB, setGmpFilter, gmpFilter, menuSelection, searchBoxValue],
    );

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {

        appService.checkAuthorized().then((authCheck) => {
            setAuthOn(true);
            appService.getFavourite().then((fav) => {
                setFavouritesDB(fav.data);

                appService.getPopular().then((pop) => {
                    setPopularDB(pop.data);

                    appService.getLastRun().then((last) => {
                        setMyLastRunDB(last.data);

                        appService.getServices().then((result) => {
                            result.data.forEach(service => {
                                if (fav.data.some(fav => fav.itemCode.includes(service?.code))) {
                                    service.isFavourite = true;
                                }
                                else {
                                    service.isFavourite = false;
                                }
                            });
                            setDataFromDB(result.data);
                            setDataFilter(result.data);
                            setDataLoaded(true);
                        });
                    });

                });

            });
        }).catch((error) => {
            window.localStorage.clear();
            window.location.reload();

        });

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);




    const isMobile = width <= 1000;
    const xxl = width >= 1400;
    const xxl2000 = width >= 2070;
    const width1200OrLower = width <= 1200;


    return (
        <div>
            {
                (() => {
                    if (isMobile && authOn) {
                        return (
                            <div>
                                <Container style={{ maxWidth: '100%' }}>
                                    <button type="button" style={{ display: visibleScrollToTop ? 'inline' : 'none', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)' }} className="button-to-top btn btn-light p-10 rounded-circle btn" onClick={scrollToTop}>
                                        <i className="bi bi-arrow-up" style={{ fontSize: 22, color: 'black' }}></i>
                                    </button>
                                    <Row style={{ paddingRight: '0px' }}>
                                        <Col sm style={{ backgroundColor: '#f5f5f5' }}>
                                            <div>
                                                <Row className="departmentsHeader" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                                    <Col xs={4}>
                                                        <div>

                                                            <Panel
                                                                isLightDismiss
                                                                isOpen={isOpen}
                                                                onDismiss={dismissPanel}
                                                                type={PanelType.customNear}
                                                                closeButtonAriaLabel="Close"
                                                                customWidth='405px'
                                                                styles={{ content: { paddingLeft: '0px' }, }}
                                                            >
                                                            </Panel>
                                                        </div>
                                                    </Col >
                                                </Row >
                                            </div>
                                            <Row>
                                                <TopBannerMobile description="Aplikacja ta pomoże Ci w zarządzaniu twoim zdjęciem" />
                                            </Row>
                                        </Col>
                                        <Col lg={2}>
                                
                                </Col>
                                <Col lg={8}>
                                        <LoadFiles />
                                </Col>
                                <Col lg={2}>
                               
                                </Col>
                                    </Row>
                                </Container >

                            </div>
                        )
                    } else if (!isMobile && authOn) {
                        return (
                            <Container style={{ maxWidth: '100%', backgroundColor: '#f5f5f5' }}>
                            <button type="button" style={{ display: visibleScrollToTop ? 'inline' : 'none', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)' }} className="button-to-top btn btn-light p-10 rounded-circle btn" onClick={scrollToTop}>
                                <i className="bi bi-arrow-up" style={{ fontSize: 22, color: 'black' }}></i>
                            </button>
                            <Row className="align-items-left" style={{ paddingRight: '0px' }}>
                                <Row style={{ paddingRight: '0px' }}>
                                    <Col sm style={{ backgroundColor: '#f5f5f5' }}>
                                    <TopBanner description="Aplikacja ta pomoże Ci w zarządzaniu twoim zdjęciem" />
                                    </Col>
                                </Row>
                                <Col lg={2}>
                                
                                </Col>
                                <Col lg={8}>
                                        <LoadFiles />
                                </Col>
                                <Col lg={2}>
                               
                                </Col>
                            </Row>
                        </Container >
                        )
                    } else {
                        return (
                            <div></div>
                        )
                    }
                })()
            }
        </div>
    );
}
