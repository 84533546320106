import React, { useState, useEffect } from 'react';
interface ToastProps {
  message: string;
  show: boolean;
  onClose: () => void;
  isLoading: boolean;
}
export const ToastComponent: React.FC<ToastProps> = ({ message, show, onClose, isLoading }) => {
  useEffect(() => {
    if (show && !isLoading) {
      // Automatically close the toast after 3 seconds
      const timer = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [show, onClose, isLoading]);

  return (
    <div
      className={`toast show position-fixed top-50 start-50 translate-middle ${
        show ? 'visible' : 'invisible'
      }`}
      style={{ zIndex: 9999 }}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >

      {!isLoading && (
        <div className="toast-body">{message}</div>
      )}
    </div>
  );
};