
import React, { useEffect, useRef, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from "./authConfig";
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsMsal2Provider } from '@microsoft/mgt-teams-msal2-provider';

export const AuthEndChangePhoto = () => {
    const { instance } = useMsal();
    const [isTeams, setIsTeams] = useState<boolean>(false);

    useEffect(() => {
        microsoftTeams.getContext(context => {
            console.log("inside contex");
            if (context === null || context === undefined) {
                setIsTeams(false);

            } else {
                setIsTeams(true);
            }
        });
        console.log("useEffect");
        console.log(process.env.PUBLIC_URL);
        instance.handleRedirectPromise()
            .then(result => {
                microsoftTeams.authentication.notifySuccess();
                window.location.href = window.location.origin + "/changePhoto";
            })
            .catch((error) => {
                if (!isTeams) {
                    window.location.href = window.location.origin + "/changePhoto";
                } else {
                    microsoftTeams.authentication.notifyFailure(error);
                }
            })

    }, []);
    return (

        <div style={styles.container}>
            <h4 className="title is-4">
                Autoryzacja....
            </h4>
        </div>
    );
};

export default AuthEndChangePhoto;

const styles = {
    container: {
        textAlign: 'center',
    },
} as const;

