import React, { useEffect, useRef, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from "./authConfig";
import * as microsoftTeams from "@microsoft/teams-js";
import { app, Context } from "@microsoft/teams-js";
import { TeamsMsal2Provider } from '@microsoft/mgt-teams-msal2-provider';
import Cookies from 'js-cookie';
import backgroundImageForLogin from '../../Assets/LoginImage.png';
import { PrimaryButton } from '@fluentui/react/lib/Button';

export const Login = () => {
    const [isTeams, setIsTeams] = useState<boolean>(false);
    const { instance } = useMsal();
    useEffect(() => {

            microsoftTeams.getContext(context => {
                console.log("inside contex");
                if (context === null || context === undefined) {
                    setIsTeams(false);

                } else {
                    setIsTeams(true);
                }
            });

        if (!isTeams) {
            instance.loginRedirect(loginApiRequest);
        }
      /*      instance.loginRedirect(loginApiRequest);*/
        //instance.loginRedirect(loginApiRequest).catch((e) => {
        //    console.log(e);
        //});

    }, []);

    const handleLogin = () => {

        //const getAccessToken = async (promptConsent: boolean = false): Promise<string> => {
        //    return new Promise<string>((resolve, reject) => {
        //        microsoftTeams.authentication.authenticate({
        //            url: window.location.origin + "/teamsauthpopup",
        //            width: 600,
        //            height: 535,
        //            successCallback: (accessToken: string) => {
        //                resolve(accessToken);
        //            },
        //            failureCallback: (reason :any) => {
        //                reject(reason);
        //            }
        //        });
        //    });
        //};
        //microsoftTeams.initialize();
        //microsoftTeams.getContext((context) => {

        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/teamsauthpopup",
            width: 600,
            height: 535,
            successCallback: (result: string) => {
                window.location.reload();
            },
            failureCallback: (response: any) => {
                window.location.reload();
            }
        })

    };
    return (

        <div style={{ display: !isTeams ? 'none' : '' }}>
        <div style={styles.container}>
            <div style={styles.windowWithButton}>
                <div style={styles.topText}>
                    Modern workplace
                </div>

                <div style={styles.buttonMaring}>
                    <PrimaryButton style={styles.buttonToLogin} onClick={handleLogin}>
                        Zaloguj się w okienku (MS Teams)
                    </PrimaryButton>
                </div>
                <div style={styles.bottomText}>
                    Modern Workplace| Polpharma © 2022 | IT 
                    </div>
            </div>
        </div>
            </div>

    );
};

export default Login;

const styles = {
    container: {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/LoginImage.png'})`,
        height: '100%',
        position: 'absolute',
        left: '0',
        width: '100%',
        overflow: 'hidden',
       backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
    },
    windowWithButton: {
        margin:'auto',
        backgroundColor: '#F8F8F8',
        minWidth: '200px',
        maxWidth: '400px',
        marginTop: '40vh',
    },
    buttonToLogin: {
        top: '20%',
        margin: '0',
        width: '75%',
        height: '100px',
    },
    bottomText: {
        color: 'black',
        marginTop:'20px',
        fontSize: '13px',
    },
    topText: {
        color: 'black',
        paddingTop: '30px',
        fontSize: '25px',
        fontWeight: 'bold',
    },
    buttonMaring: {
        marginTop: '60px'
    },
} as const;

