import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bulma/css/bulma.min.css';
import "./index.css";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginApiRequest } from "./Utils/auth/authConfig";
import { msalConfigChangePhoto } from "./Utils/auth/authConfigChangePhoto";
import { msalConfigWeb } from "./Utils/auth/authConfigWeb";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import * as msTeams from '@microsoft/teams-js';
msTeams.initialize();
var msalInstance: any;
console.log("URL BASE");
console.log(window.location.href);
if (!window.location.href.includes("changephoto")) {
    msalInstance = new PublicClientApplication(msalConfig);
} else {
    msalInstance = new PublicClientApplication(msalConfigChangePhoto);
}
//web
axios.defaults.baseURL = process.env.PUBLIC_URL + '/api/';
msalInstance.handleRedirectPromise();
//local
/*axios.defaults.baseURL = "https://localhost:7203/api/";*/
console.log(axios.defaults.baseURL);
initializeIcons();
axios.interceptors.request.use(
    async (response: any) => {
        const account = msalInstance.getAllAccounts()[0];
        const msalResponse = await msalInstance.acquireTokenSilent({
            ...loginApiRequest,
            account: account,
        });
        response.headers.common.Authorization = `Bearer ${msalResponse.accessToken}`;
        return response;
    },
    (err) => {
        return Promise.reject(err);
    }
);


ReactDOM.render(
    <React.StrictMode>

        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>

    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
