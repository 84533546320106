import { useMsal } from "@azure/msal-react";
import banner from '../img/banner.png';
import { IPivotStyles, IStyleSet, Label, Pivot, PivotItem } from '@fluentui/react';
import { Col, Row } from "react-bootstrap";
import React, { useState } from 'react';
import { ITheme, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
const theme: ITheme = getTheme();
const { palette, fonts } = theme;


const pivotStyles1200: Partial<IStyleSet<IPivotStyles>> = {
    link: {
        margin: '0',
        fontSize: fonts.medium.fontSize,
        textAlign: 'left',
    },
    linkIsSelected: {
    }
};

const pivotStyles: Partial<IStyleSet<IPivotStyles>> = {
    link: {
        margin: '0',
        fontSize: fonts.large.fontSize,
        textAlign: 'left',
    },
    linkIsSelected: {
    }
};
export const TopNavMenu = (props: any) => {
    return (
        <Pivot styles={props.width1200OrLower ? pivotStyles1200 : pivotStyles}
            selectedKey={props.selectedKey !== 'all' && props.selectedKey !== 'favourite' && props.selectedKey !== 'last' && props.selectedKey !== 'popular' ? null : props.selectedKey}
            onLinkClick={props.onLinkClick}>
            <PivotItem headerText="Wszystkie" itemKey="all"></PivotItem>
            <PivotItem headerText="Ulubione" itemKey="favourite"></PivotItem>
            <PivotItem headerText="Ostatnio uruchamiane aplikacje" itemKey="last"></PivotItem>
            <PivotItem headerText="Najpopularniejsze w organizacji" itemKey="popular"></PivotItem>s
        </Pivot>
    );
}
