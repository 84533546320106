import { LogLevel } from "@azure/msal-browser";

export const msalConfigChangePhoto = {
    auth: {
        clientId:  "fca01b7b-9943-4fe5-95fc-faa74031b627", // Client ID 
        authority: 'https://login.microsoftonline.com/edf3cfc4-ee60-4b92-a2cb-da2c123fc895', // Tenant ID of the React.JS App Registration
        //local
        redirectUri: process.env.PUBLIC_URL + '/authendchangephoto', /*"https://localhost:3000/authendchangephoto" ,*/

        //web
       /* redirectUri: "https://jwaleka-dev-webapp.azurewebsites.net/authend", */
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
        
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: string, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequestChangePhoto = {
    scopes: ["api://e0252912-9960-487f-9d95-94c0488e9b2f/api.scope"],
};