import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Icon } from '@fluentui/react/lib/Icon';
import { Container, Row, Col } from "react-bootstrap"
import { DefaultButton, IButtonProps } from '@fluentui/react/lib/Button';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import { useBoolean, useId } from '@fluentui/react-hooks';
import './ServiceDetailedInfo.css';
export const ServiceDetailedInfo: React.FunctionComponent<{
    serviceName: string | undefined,
    apqcArea: string | undefined,
    bpArea: string | undefined,
    businessOwner: string | undefined,
    businessAdministrator: string | undefined,
    managerIT: string | undefined,
    supportTime: string | undefined
}> = ({ children, serviceName, apqcArea, bpArea, businessOwner, businessAdministrator, managerIT, supportTime }) => {
    const buttonId = useId('targetButton');
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
    const examplePrimaryButtonProps: IButtonProps = {
        children: 'Try it out',
        onClick: toggleTeachingBubbleVisible,
    };
    const iconButtonStyles = {
        alignItems: 'normal',
        root: {
            fontSize: '10px'


        },
        rootHovered: {
            color: '#dd1d21',
        },
    };

    function getBpName(keyName: string | undefined) {
        if (keyName !== undefined) {
            switch (keyName) {
                case 'API':
                    return 'Aktywne substancje farmaceutyczne (API)';
                case 'Business Development and R&D':
                    return 'Badania i rozwój biznesu'
                case 'Finance':
                    return 'Finanse'
                case 'HR':
                    return 'HR'
                case 'Industrial Operations':
                    return 'Operacje przemysłowe'
                case 'Procurement':
                    return 'Obszar zakupowy'
                case 'Quality':
                    return 'Jakość'
                case 'Group Commercial & Marketing':
                    return 'Sprzedaż i marketing'
                case 'Corporate Development':
                    return 'Rozwój korporacyjny'
                case 'Legal':
                    return 'Obszar prawny'
                case 'PR':
                    return 'PR'
                case 'Administration':
                    return 'Administracja'
                case 'IT':
                    return 'IT'
                case 'Security':
                    return 'Bezpieczeństwo'
                default:
                // code block
            }
        }
    }
    return (
        <div>
            <i id={buttonId} className="bi bi-info-circle-fill" onClick={toggleTeachingBubbleVisible} style={{ fontSize: 25, color: 'black', cursor: 'pointer' }}></i>
            {teachingBubbleVisible && (
                <TeachingBubble
                    target={`#${buttonId}`}
                    calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                    styles={iconButtonStyles}
                    hasSmallHeadline={true}
                    onDismiss={toggleTeachingBubbleVisible}
                    headline="Informacje"
                    closeButtonAriaLabel="Close"
                >
                    <div style={{ fontSize: '11px' }}>

                        <Row>
                            <Col sx={6}>Nazwa usługi:</Col>
                            <Col style={{ fontSize: '12px' }} sx={6}>{serviceName}</Col>
                        </Row>
                        <Row>
                            <Col sx={6}>Grupa procesów:</Col>
                            <Col style={{ fontSize: '12px' }} sx={6}>{apqcArea}</Col>
                        </Row>
                        <Row>
                            <Col sx={6}>Obszar biznesowy:</Col>
                            <Col style={{ fontSize: '12px' }} sx={6}>{getBpName(bpArea)}</Col>
                        </Row>
                        <Row>
                            <Col sx={6}>Właściciel biznesowy:</Col>
                            <Col style={{ fontSize: '12px' }} sx={6}>{businessOwner}</Col>
                        </Row>
                        <Row>
                            <Col sx={6}>Administrator biznesowy:</Col>
                            <Col style={{ fontSize: '12px' }} sx={6}>{businessAdministrator}</Col>
                        </Row>
                        <Row>
                            <Col sx={6}>Menedżer informatyczny:</Col>
                            <Col style={{ fontSize: '12px' }} sx={6}>{managerIT}</Col>
                        </Row>
                        <Row>
                            <Col sx={6}>Dostępność usługi:</Col>
                            <Col style={{ fontSize: '12px' }} sx={6}>{supportTime}</Col>
                        </Row>
                    </div>
                </TeachingBubble>
            )}
        </div>
    );
};
