//import {
//    useMsal,
//    AuthenticatedTemplate,
//    UnauthenticatedTemplate,
//} from "@azure/msal-react";
//import {  } from "@azure/msal-browser";
//import { Employee } from "./components/Employee";
//import { SignInButton } from "./components/SignInButton";
//import { SignOutButton } from "./components/SignOutButton";

//function App() {

//    return (
//        <div>
//            <section className="section">
//                <div className="container">
//                    <div className="columns is-centered">
//                        <div className="column is-half">
//                            <AuthenticatedTemplate>
//                                <SignOutButton />
//                                <Employee />
//                            </AuthenticatedTemplate>
//                            <UnauthenticatedTemplate>

//                                    <SignInButton />

//                            </UnauthenticatedTemplate>
//                        </div>
//                    </div>
//                </div>
//            </section>
//        </div>
//    );
//}

//export default App;


import React, { useEffect, useRef, useState } from 'react';
import { TeamsThemeHelper } from './Utils/helpers';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { Provider, ThemePrepared } from '@fluentui/react-northstar';
import * as msTeams from '@microsoft/teams-js';
import { Login } from './Utils/auth';
import { Main, Home, ChangePhoto } from './Pages';
import TeamsAuthPopup from './Utils/auth/team-auth-popup';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthEnd from './Utils/auth/auth-end';
import AuthEndChangePhoto from './Utils/auth/authEndChangePhoto';

export default class App extends React.Component<IAppProps, IAppState> {

    constructor(props: IAppProps) {
        super(props);

        this.state = {
            theme: TeamsThemeHelper.getTheme('default'),
            auth: false
        };
       
        msTeams.registerOnThemeChangeHandler(this.updateTheme.bind(this));
        msTeams.getContext(context => { this.updateTheme(context.theme) });


        //this.init().then((response) => {
        //    let authResponse = response;
        //    this.setState({
        //        auth: authResponse
        //    });
        //})
    }


    render() {
        return (
            <div>
                {
                    <Provider theme={this.state.theme}>
                  
                            {/*<BrowserRouter>*/}
                            {/*    <Routes>*/}
                            {/*        <Route path="/" element={<Home />} />*/}
                            {/*    </Routes>*/}
                            {/*</BrowserRouter>*/}
             
                     
                        



                        <AuthenticatedTemplate>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/teamsauthpopup" element={<TeamsAuthPopup />} />
                                    <Route path="/changePhoto" element={<ChangePhoto />} />
                                    <Route path="/authend" element={<AuthEnd />} />
                                    <Route path="/authendchangephoto" element={<AuthEndChangePhoto />} />
                                </Routes>
                            </BrowserRouter>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<Login />} />
                                    <Route path="/changePhoto" element={<Login />} />
                                    <Route path="/teamsauthpopup" element={<TeamsAuthPopup />} />
                                    <Route path="/authend" element={<AuthEnd />} />
                                    <Route path="/authendchangephoto" element={<AuthEndChangePhoto />} />
                                    
                                </Routes>
                            </BrowserRouter>
                           </UnauthenticatedTemplate>


                    </Provider>
                }
            </div>
        )
    }

    private updateTheme(themeString: string | undefined): void {
        this.setState({
            theme: TeamsThemeHelper.getTheme(themeString)
        });
    }

    //private async init() {
    //    let response = await instance.handleRedirectPromise();
    //    console.log("app");
    //    console.log(response);
    //    if (response != null && response?.account?.username) {
    //        return true;
    //    } else {
    //        const accounts = instance.getAllAccounts();
    //        if (accounts === null || accounts.length === 0) {
    //            return false;
    //        } else if (accounts.length > 1) {
    //            throw new Error("ERROR: Multiple accounts are logged in");
    //        } else if (accounts.length === 1) {
    //            return true;
    //        }
    //    }

    //}
}

interface IAppProps { }

interface IAppState {
    theme: ThemePrepared;
    auth: Boolean | undefined;
}