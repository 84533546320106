import React, { useEffect, useState } from 'react';
import { Nav, INavLinkGroup, INavStyles, INavLink } from '@fluentui/react/lib/Nav';
import { Col } from "react-bootstrap"
import { FontSizes } from '@fluentui/react';

const navStyles: Partial<INavStyles> = {
    root: {
        width: '100%',
        paddingTop: '10px',
        paddingLeft: '10px',
    },
    
};

const navLinkGroups1: INavLinkGroup[] = [
    {
        name: 'Basic components',
        links: [
            {
                key: 'ActivityItem',
                name: 'ActivityItem',
                url: '#/examples/activityitem',
            },
            {
                key: 'Breadcrumb',
                name: 'Breadcrumb',
                url: '#/examples/breadcrumb',
            },
            {
                key: 'Button',
                name: 'Button',
                url: '#/examples/button',
            },
        ],
    },
];

const navLinkGroups: INavLinkGroup[] = [
    {
        name: 'Katalog aplikacji IT',
        links: [
            {
                name: 'Wszystkie', url: '', key: 'all', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Aktywne substancje farmaceutyczne (API)', url: '', key: 'API', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Badania i rozwój biznesu', url: '', key: 'Business Development and R&D', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Finanse', url: '', key: 'Finance', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'HR', url: '', key: 'HR', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Operacje przemysłowe', url: '', key: 'Industrial Operations', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Obszar zakupowy', url: '', key: 'Procurement', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Jakość', url: '', key: 'Quality', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Sprzedaż i marketing', url: '', key: 'Group Commercial & Marketing', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Rozwój korporacyjny', url: '', key: 'Corporate Development', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Obszar prawny', url: '', key: 'Legal', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'PR', url: '', key: 'PR', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Administracja', url: '', key: 'Administration', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'IT', url: '', key: 'IT', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
            {
                name: 'Bezpieczeństwo', url: '', key: 'Security', iconProps: {
                    styles: {
                        root: { color: 'black' },
                    },
                },
            },
        ]
    }
];


export const LeftNavMenu = (props: any) => {

    return (
        <Nav selectedKey={props.menuSelection}
            styles={navStyles}
            onRenderGroupHeader={_onRenderGroupHeader}
            onRenderLink={_onRenderLink}
            onLinkClick={props.onMenuItemSelected}
            groups={navLinkGroups}
        />
    );
};

function _onRenderGroupHeader(group: INavLinkGroup | undefined): JSX.Element {
    return <div style={{ marginLeft: "0px", fontSize: '25px',paddingLeft:'10px', marginBottom: '20px', marginTop: '10px', color:'black' }}>{group?.name} </div>;
}

function _onRenderLink(link: INavLink | undefined): JSX.Element {
    let iconClass = '';
    if (link?.key !== undefined) {
        switch (link?.key) {
            case 'all':
                iconClass = 'bi bi-x-diamond-fill'
                break;
            case 'API':
                iconClass = 'bi bi-asterisk'
                break;
            case 'Business Development and R&D':
                iconClass = 'bi bi-briefcase-fill'
                break;
            case 'Finance':
                iconClass = 'bi bi-bank2'
                break;
            case 'HR':
                iconClass = 'bi bi-people-fill'
                break;
            case 'Industrial Operations':
                iconClass = 'bi bi-boxes'
                break;
            case 'Procurement':
                iconClass = 'bi bi-graph-up-arrow'
                break;
            case 'Quality':
                iconClass = 'bi bi-gem'
                break;
            case 'Group Commercial & Marketing':
                iconClass = 'bi bi-arrow-repeat'
                break;
            case 'Corporate Development':
                iconClass = 'bi bi-globe2'
                break;
            case 'Legal':
                iconClass = 'bi bi-journal-check'
                break;
            case 'PR':
                iconClass = 'bi bi-bullseye'
                break;
            case 'Administration':
                iconClass = 'bi bi-geo-alt-fill'
                break;
            case 'IT':
                iconClass = 'bi bi-laptop'
                break;
            case 'Security':
                iconClass = 'bi bi-shield-shaded'
                break;
            default:
            // code block
        }
    }

    return (
        <div style={{ fontSize: '15px' }}>
            <i className={iconClass} style={{ fontSize: 18, color: '#404040', paddingLeft: '10px', paddingRight: '10px', paddingTop: '40px' }}></i>
            {link?.name}
        </div>
    );
}

